/* BEGIN LivePerson View cart Monitor. */
  $(document).on('addToCart.success', function(event, data) {
    var cartItems = data.trans_data.order.items;
    var cartInformation = {};
    cartInformation["type"] = "cart";
    cartInformation["total"] = data.trans_data["subtotal"];
    cartInformation["numItems"] = cartItems.length;
    cartInformation["products"] = [];
    cartItems.forEach(function(product) {
      var products = {};
      products["product"] = {};
      products["product"]["name"] = product["prod.PROD_RGN_NAME"];
      products["product"]["category"] = product["prod.FAMILY_CODE"];
      products["product"]["sku"] = product["sku.SKU_ID"];
      products["product"]["price"] = product["UNIT_PRICE"];
      products["quantity"] = product["ITEM_QUANTITY"];
      cartInformation["products"].push(products);
    });
    lpTag.sdes = lpTag.sdes||[];
    lpTag.sdes.push(cartInformation);
  });
/* END LivePerson View cart Monitor. */

